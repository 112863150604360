import React, { Component } from 'react';
import HomePage from './homepage/home';
import './App.css';
import {
  Router,
  Route,
  Switch
} from 'react-router-dom';
import createHistory from 'history/createBrowserHistory'
import ReactGA from 'react-ga';
import Contact from './contact/contact';
import Faq from './faq/faq';
import Products from './products/products';
import Funded from './getfunded/getfunded';
import About from './about/about';
import error404 from './errorpages/404';
import error403 from './errorpages/403';
import JoinUs from './joinus/joinus';
import sitemap from './sitemap/sitemap';
import Invest from './invest/invest';
import Repo from './repo/repo';
import Landing from './landing/landing';
import Assets from './assets_page/assets';
import Events from './events/events';
import SingleEvent from './events/event-single';
import Testimonials from './testimonials/testimonials';
import PressRelease from './press_release/press_relases';
import SinglePress from './press_release/single-press';
import Awards from './awards/awards';
import Blog from './blog/blog';
import SingleBlog from './blog/single_blog';
import Interviews from './interviews/interviews';
import SingleInterview from './interviews/singleInterview';
import InvestmentNews from './investment_news/investment_news';
import Podcast from './podcast/podcast';
import Episode from './podcast/episode';
import { AlertProvider } from './context/AlertContext';
import Foundation from './rehbar_foundation/foundation';

const history = createHistory()
ReactGA.initialize('UA-54309354-5');
history.listen((location, action) => {
  ReactGA.pageview(location.pathname);
});

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <AlertProvider>
          <Switch>
            <Route path="/" component={HomePage} exact />
            <Route path="/invest" component={Invest} exact />
            <Route path="/contact" component={Contact} exact />
            <Route path="/faq" component={Faq} exact />
            <Route path="/products_and_services" component={Products} exact />
            <Route path="/products_and_services/:id" component={Products} exact />
            <Route path="/get_funded" component={Funded} exact />
            <Route path="/about-us" component={About} exact />
            <Route path="/repo" component={Repo} exact />
            <Route path="/about-us/foundation" component={Foundation} />
            <Route path="/about-us/:id" component={About} exact />
            <Route path="/assets" component={Assets} exact />
            <Route path="/events" component={Events} exact />
            <Route path="/events/:title" component={SingleEvent} exact />
            <Route path="/testimonials" component={Testimonials} exact />
            <Route path="/press-release" component={PressRelease} exact />
            <Route path="/press-release/:title" component={SinglePress} exact />
            <Route path="/awards" component={Awards} exact />
            <Route path="/blog" component={Blog} exact />
            <Route path="/blog/:title" component={SingleBlog} exact />
            <Route path="/interviews" component={Interviews} exact />
            <Route path="/interviews/:title" component={SingleInterview} exact />
            <Route path="/investment-news" component={InvestmentNews} exact />
            <Route path="/podcast" component={Podcast} exact />
            <Route path="/podcast/:title" component={Episode} exact />
            <Route path="/interviews" component={Interviews} exact />
            <Route path="/interview/:title" component={SingleInterview} exact />
            <Route path="/404" component={error404} exact />
            <Route path="/403" component={error403} exact />
            <Route path="/join_us" component={JoinUs} exact />
            <Route path="/landing" component={Landing} exact />
            <Route path="/:id" component={Landing} />
          </Switch>
        </AlertProvider>
      </Router>
    );
  }
}

export default App;
