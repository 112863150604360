import React, { Component } from "react"
import Header from "../header/header"
import Footer from "../footer/footer"
import "./awards.css"
import cachios from 'cachios'
import HtmlParser from "react-html-parser"
import { BASEURL } from "../api/api"
import Loader from "../styles/loader"


class Awards extends Component {
    constructor() {
        super()
        this.state = {
            awards: null,
            details: null,
            included: []
        }
    }
    componentDidMount() {
        cachios.get(`${BASEURL}/jsonapi/node/awards?include=field_image&sort=-field_date`)
            .then(resp => {
                this.setState({
                    awards: resp.data.data,
                    included: resp.data.included
                })
            })
            .catch(err => {
                console.log("error on awards listing", err)
            })
        cachios.get(`${BASEURL}/jsonapi/block_content/title_description?filter[drupal_internal__id]=33`)
            .then(resp => {
                this.setState({
                    details: resp.data.data[0]
                })
            })
            .catch(err => {
                console.error(err, "error on details in awards")
            })
    }
    render() {
        return (<div id="awards_page">
            <Header />
            <div className="awards_page">
                {this.state.details?.attributes ? <div className="head-banner">
                    <p className="sub-head orange-text text-center mb-2 pb-1">{this.state.details.attributes.field_title?.value}</p>
                    <h2 className="heading black-text text-center mb-sm-4 mb-3">{this.state.details.attributes.info}</h2>
                    <p className="support-text teritiary-text text-center">{this.state.details.attributes.field_description[0] ? HtmlParser(this.state.details.attributes.field_description[0].value) : ""}</p>
                </div> : <div className="w-100 my-5"> <Loader type="content" /></div>}
                <div className="awards d-flex flex-wrap">
                    {this.state.awards?.map((single, ind) => {
                        let image = single.relationships.field_image?.data ? this.state.included?.find(x => x.id === single.relationships.field_image?.data.id)?.attributes.uri.url : "",
                            date = new Date(single.attributes.field_date), options = { day: "numeric", month: "short", year: "numeric" }
                        let formated_date = date.toLocaleDateString("en-GB", options)
                        return <div className="col-sm-4 col-12">
                            <div className="image-container position-relative w-100">
                                <img src={image ? BASEURL + image : "/assets/noimage.png"} className="w-100" />
                                <div className="fixed-date">
                                    <div className="fields">
                                        <p className="text-sm-semibold white-text mb-0">Date</p>
                                        <p className="white-text text-sm-regular mb-0">{formated_date}</p>
                                    </div>
                                </div>
                            </div>
                            <h4 className="display-xs-semibold primary-text">{single.attributes.title}</h4>
                            <p className="text-md-regular teritiary-text">{single.attributes.field_short_description ? HtmlParser(single.attributes.field_short_description.value) : ""}</p>
                        </div>
                    })}
                    {this.state.awards ? this.state.awards.length === 0 ? <div className="no-data">No Awards found</div> : "" : <Loader type="carousel" />}
                </div>
            </div>
            <Footer />
        </div>)
    }
}
export default Awards;