import React, { Component } from "react"
import Header from "../header/header";
import Footer from "../footer/footer";
import { events } from "./event_data";
import cachios from "cachios"
import HTMLparser from "react-html-parser"
import { Link } from "react-router-dom";
import { BASEURL } from "../api/api";
import Loader from "../styles/loader";


class SingleEvent extends Component {
    constructor() {
        super()
        this.state = {
            data: null
        }
    }
    componentDidMount() {
        const title = this.props.match.params.title.replace(/-/g, " ")
        this.getSingleEvent(title)
    }
    getSingleEvent(title) {
        window.scrollTo(0, 0)
        let path = window.location.pathname
        cachios.get(`${BASEURL}/jsonapi/node/events?include=field_image&filter[field_path_alias]=${path}`)
            .then(resp => {
                if (resp.data.data.length) {
                    let data = resp.data.data[0],
                        included = resp.data.included
                    console.log("events", resp.data.data)
                    this.setState({
                        data: resp.data.data[0],
                        included: resp.data.included,
                        date: resp.data.data[0].attributes.field_event_date_and_time ? new Date(resp.data.data[0].attributes.field_event_date_and_time) : "",
                        image: data.relationships.field_image.data ? included?.find(x => x.id === data.relationships.field_image.data.id)?.attributes.uri.url : ""
                    })
                    cachios.get(`${BASEURL}/jsonapi/node/events?include=field_image&filter[nid][field]=drupal_internal__nid&filter[nid][operator]=<>&filter[nid][value]=${data.attributes.drupal_internal__nid}&sort=-field_event_date_and_time&page[offset]=0&page[limit]=3`)
                        .then(resp1 => {
                            this.setState({
                                other_events: resp1.data.data,
                                otherEventns_included: resp1.data.included
                            })
                        })
                        .catch(err => {
                            console.log("error in other events", err)
                        })
                }
            })
            .catch(err => {
                console.error(err, "error in events listing")
            })
    }
    formatDate(date) {
        // Months array
        const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        // Get day, month, year, hour, and minute from the date object
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        let hour = date.getHours();
        const minute = date.getMinutes();

        // Convert hour to 12-hour format and determine AM/PM
        let ampm = 'AM';
        if (hour >= 12) {
            ampm = 'PM';
            hour -= 12;
        }
        if (hour === 0) {
            hour = 12;
        }

        // Format the date string
        const formattedDate = `${day} ${month} ${year}, ${hour}:${minute < 10 ? '0' : ''}${minute} ${ampm}`;

        return formattedDate;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.title !== this.props.match.params.title) {
            const title = this.props.match.params.title.replace(/-/g, " ")
            this.getSingleEvent(title)
        }
    }
    render() {
        return (
            <div id="single-event">
                <Header />
                <div className="event-single pt-0 events-container">
                    {this.state.data ? <div className="breadcrumbs d-flex">
                        <p className="teritiary-text text-sm-regular mb-0">Press</p>
                        <img src="/assets/bread-right.png" />
                        <p className="teritiary-text text-sm-regular mb-0"><Link className="teritiary-text" to="/events">Events</Link></p>
                        <img src="/assets/bread-right.png" />
                        <p className="text-sm-regular mb-0 orange-text">{this.state.data.attributes.title}</p>
                    </div> : ""}
                    {this.state.data ? <>
                        <div className="header-text-banner align-items-start">
                            <h2 className="primary-text display-lg-regular">{this.state.data.attributes.title}</h2>
                            <p className="teritiary-text text-xl-regular">{this.state.data.attributes.field_short_description ? HTMLparser(this.state.data.attributes.field_short_description.value) : ""}</p>
                            <div className="d-sm-flex d-block venue-date teritiary-text text-xl-regular">
                                {this.state.data.attributes.field_location_link ? <a href={this.state.data.attributes.field_location_link.uri} target="_blank" ><p className="teritiary-text text-xl-regular mb-sm-0 mb-2 pb-sm-0 pb-1"><img className="mr-3" src="/assets/location.png" width="16px" height={"20px"} />{this.state.data.attributes.field_location_link ? this.state.data.attributes.field_location_link.title : ""}</p></a> : ""}
                                <p className="text-xl-regular"><img className="mr-3" src="/assets/calender.png" width="18px" height={"20px"} />{this.state.date ? this.formatDate(this.state.date) : ""}</p>
                            </div>
                        </div>
                        {this.state.image ? <img className="w-100 mt-5 pt-3 mb-5" src={BASEURL + this.state.image} /> : ""}
                        <div className="main-description mx-auto">
                            {this.state.data?.attributes.body ? HTMLparser(this.state.data.attributes.body.value.replace(/src="\/sites/g, `src="${BASEURL}/sites`)) : ""}
                        </div>
                        <div className="other-events">
                            <h3 className="display-xs-semibold mb-4 pb-2">Other Events</h3>
                            <div className="carousel-one">
                                {this.state.other_events?.map(single => {
                                    let image = single.relationships.field_image?.data ? this.state.otherEventns_included?.find(x => x.id === single.relationships.field_image.data.id)?.attributes.uri.url : "",
                                        date = new Date(single.attributes.field_event_date_and_time)
                                    const options = { month: "short", day: "numeric", year: "numeric" }
                                    let final_date = date.toLocaleDateString('en-GB', options)
                                    return <div className="single-card">
                                        <img className="w-100 image mb-3" src={image ? BASEURL + image : "/assets/noimage.png"} />
                                        <p className="orange-text pt-1 mb-2 text-sm-semibold">{final_date}</p>
                                        <div className="d-flex justify-content-between">
                                            <h5 className="black-text title px-0 mb-2 mt-0">{single.attributes.title}</h5>
                                            <Link to={`/events/${single.attributes.title.toLowerCase().replace(/ /g, "-")}`}><img className="open-arrow" src="/assets/top-right-arrow.png" /></Link>
                                        </div>
                                        <p className="primary-text text-md-regular mb-4">{single.attributes.field_short_description ? HTMLparser(single.attributes.field_short_description.value) : ""}</p>
                                    </div>
                                })}
                            </div>
                        </div>
                    </> : <Loader type="content" />}

                </div>
                <Footer />
            </div>
        )
    }
}
export default SingleEvent;