import React, { Component, usepa } from "react";
import { useParams } from "react-dom"
import Header from "../header/header";
import Footer from "../footer/footer";
import cachios from 'cachios'
import { BASE } from "../api/api";
import HtmlParser from "react-html-parser";
import releases, { other_details } from "./press_data";
import { Link } from "react-router-dom";
import Loader from "../styles/loader";
import OtherDetails from "../styles/other-details";

class SinglePress extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: props.match.params.title.replace(/-/g, " "),
            data: null,
            date: null,
            Included: []
        }
        this.updateData = this.updateData.bind(this)
    }
    componentDidMount() {
        console.log("change varumo")
        window.scrollTo(0, 0)
        if (this.state.title) {
            let data = releases.find(x => x.title.toLowerCase() === this.state.title)
            cachios.get(`${BASE}/jsonapi/node/press_release?include=field_image,field_author_image,field_attachment&filter[field_path_alias]=${window.location.pathname}`, {
                ttl: 300 /* seconds */,
            })
                .then(resp => {
                    console.log("press releases single", resp.data.data)
                    const options = { day: 'numeric', month: 'short', year: 'numeric' };
                    if (resp.data.data.length) {
                        const date = new Date(resp.data.data[0].attributes.field_date);
                        let data = resp.data.data[0],
                            included = resp.data.included
                        this.setState({
                            data: resp.data.data[0],
                            date: resp.data.data[0].attributes.field_date ? new Date(resp.data.data[0].attributes.field_date).toLocaleDateString('en-GB', options) : "",
                            Included: resp.data.included,
                            pdf: data.relationships.field_attachment.data ? included.find(x => x.id === data.relationships.field_attachment.data.id).attributes.uri.url : "",
                            image: resp.data.data[0].relationships.field_image.data ? resp.data.included.find(x => x.id === resp.data.data[0].relationships.field_image.data.id).attributes.uri.url : ""
                        }, () => {
                            console.log("title", this.state.data.attributes)
                        })
                    }
                })
                .catch(err => {
                    console.log("error on single", err)
                })
            this.setState({
                data: data
            })
        }
    }
    shareTo(source) {
        const url = window.location.href
        const title = this.state.data.attributes.title
        const link = document.createElement('a');
        if (source === "facebook") {
            link.href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`
            link.target = '_blank';
            document.body.appendChild(link);
            // Programmatically click the link
            link.click();
        }
        else if (source === "linkedin") {
            link.href = `https://www.linkedin.com/shareArticle?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}`
            link.target = '_blank';
            document.body.appendChild(link);
            // Programmatically click the link
            link.click();
        }
        else if (source === "x") {
            link.href = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(title)}`
            link.target = '_blank';
            document.body.appendChild(link);
            // Programmatically click the link
            link.click();
        }
        else if (source === "copy") {
            navigator.clipboard.writeText(window.location.href);
            this.setState({
                formvalid: true,
                formvalidtext: 'Link copied to clipboard!',
                formsuccess: true
            });
            setTimeout(
                function () {
                    this.setState({ formvalid: false, form2: false });
                }
                    .bind(this),
                3000
            );
        }
    }
    updateData() {
        this.setState({
            formvalid: true,
            formvalidtext: 'Link copied to clipboard!',
            formsuccess: true
        });
        setTimeout(
            function () {
                this.setState({ formvalid: false, form2: false });
            }
                .bind(this),
            3000
        );
    }
    render() {
        return (<div id="single-press">
            <div style={{ display: this.state.formvalid ? '' : 'none', backgroundColor: this.state.formsuccess ? '#28a745' : '', position: 'fixed', top: 0, width: '100%' }} className='formvalidation'>
                {this.state.formvalidtext}
            </div>
            <Header />
            <div className="single_press press_releases-container">
                {this.state.data ? <div className="breadcrumbs d-flex">
                    <p className="teritiary-text text-sm-regular mb-0">Press</p>
                    <img src="/assets/bread-right.png" />
                    <p className="teritiary-text text-sm-regular mb-0"><Link className="teritiary-text" to="/press-release">Press Release</Link></p>
                    <img src="/assets/bread-right.png" />
                    <p className="text-sm-regular mb-0 orange-text">{this.state.data.attributes.title}</p>
                </div> : ""}
                {this.state.data ? this.state.data.attributes ? <div className="single-press-container w-100 px-sm-5 px-0">
                    <p className="news-paper text-center orange-text mb-sm-2 mb-1 pb-1">{this.state.data.attributes.field_source ? this.state.data.attributes.field_source.value : ""}</p>
                    <h3 className="title Meuso_300 primary-text text-center mb-sm-4 mb-3">{this.state.data.attributes ? this.state.data.attributes.title : ""}</h3>
                    <p className="short-desc teritiary-text mb-sm-4 mb-3 text-center">{this.state.data.attributes.field_short_description ? HtmlParser(this.state.data.attributes.field_short_description.value) : ""}</p>
                    <p className="author primary-text mb-0 text-center">{this.state.data.attributes.field_author_name ? this.state.data.attributes.field_author_name.value : ""}</p>
                    <p className="date teritiary-text text-center">{this.state.date}</p>
                    {this.state.image ? <img className="w-100 main-img" src={BASE + this.state.image} /> : ""}
                    <div className="main-description mx-auto">
                        {this.state.data.attributes.body ? HtmlParser(this.state.data.attributes.body.value.replace(/src="\/sites/g, `src="${BASE}/sites`)) : ""}
                        <OtherDetails title={this.state.title} pdf={this.state.pdf} />
                    </div>
                </div> : "" : <div className="my-5 py-5" style={{ minHeight: `${window.innerHeight - 400}px` }}> <Loader type={"content"} /></div>}

            </div>
            <Footer />
        </div>)
    }
}
export default SinglePress;