import React, { Component } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import "./events.css"
import { events } from "./event_data";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import cachios from "cachios";
import { BASEURL } from "../api/api";
import HtmlParser from "react-html-parser"
import Loader from "../styles/loader";




class Events extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tab: "upcoming",
            counta: 0,
            upcount: 0,
            pastcount: 0,
            events: null,
            included: [],
            limit: 9,
            page: 0
        }
    }
    componentDidMount() {
        this.getEvents(0, this.state.limit)
        this.upComingEvents(0, this.state.limit)
        this.pastEvents(0, this.state.limit)
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.page !== prevState.page) {
            if (this.state.tab === "past")
                this.pastEvents(this.state.page)
            else if (this.state.tab === "upcoming")
                this.upComingEvents(this.state.page)
            else
                this.getEvents(this.state.page)
        }
        if (this.state.tab !== prevState.tab) {
            this.setState({
                page: 0
            })
        }
    }
    upComingEvents(page) {
        const date = new Date()
        cachios.get(`${BASEURL}/api/upcoming-events?page=${page}`)
            .then(resp => {
                console.log("events", resp.data.data)
                let data = resp.data.rows
                this.setState({
                    up_events: data,
                    up_included: resp.data.included,
                    upcount: resp.data.pager?.total_pages
                })
            })
            .catch(err => {
                console.error(err, "error in events listing")
            })
    }
    pastEvents(page) {
        const date = new Date()
        let current_date = Math.floor(date.getTime() / 1000)
        console.log("curent", current_date); // Output: "2024-04-11T15:54:20+05:30" (assuming the current date is April 11, 2024)

        cachios.get(`${BASEURL}/api/past-events?page=${page}`)
            .then(resp => {
                console.log("past events", resp.data.data)
                let data = resp.data.rows
                this.setState({
                    past_events: data,
                    past_included: resp.data.included,
                    pastcount: resp.data.pager?.total_pages
                })
            })
            .catch(err => {
                console.error(err, "error in events listing")
            })
    }
    getEvents(page, limit) {
        let offset = page == 0 ? 0 : page * this.state.limit - 1
        cachios.get(`${BASEURL}/jsonapi/node/events?include=field_image&page[offset]=${offset}&page[limit]=${limit}`)
            .then(resp => {
                console.log("events", resp.data.data)
                this.setState({
                    events: resp.data.data,
                    included: resp.data.included,
                    counta: Math.ceil(resp.data.meta?.count / 9)
                })
            })
            .catch(err => {
                console.error(err, "error in events listing")
            })
    }
    handlePageClick(e) {
        console.log("change", e.nextSelectedPage + 1)
        this.setState({
            page: (e.nextSelectedPage)
        })
    }
    render() {
        return (
            <div id="events-page">
                <Header />
                <div className="events events-container">
                    <h2 className="heading black-text mb-sm-5 mb-3">Events</h2>
                    <div className="tabs d-sm-flex d-none mb-sm-5 mb-3">
                        <div className={`single-tab text-md-semibold secondary-text ${this.state.tab === "upcoming" ? "active" : ""}`} onClick={() => this.setState({ tab: "upcoming" })}>Upcoming Events</div>
                        <div className={`single-tab text-md-semibold secondary-text ${this.state.tab === "past" ? "active" : ""}`} onClick={() => this.setState({ tab: "past" })}>Past Events</div>
                    </div>
                    <select className="tab-select-box d-block d-sm-none text-md-medium w-100 mb-4" value={this.state.tab} onChange={(e) => this.setState({ tab: e.target.value })} style={{ backgroundImage: "url(/assets/select-down-arrow.png)" }}>
                        <option className="text-md-medium primary-text" value="">All Events</option>
                        <option className="text-md-medium primary-text" value="upcoming">Upcoming</option>
                        <option className="text-md-medium primary-text" value="past">Past</option>
                    </select>
                    <div className="carousel-one">
                        {this.state.tab === "past" ? this.state.past_events?.map((single, ind) => {
                            let date = new Date(single.field_event_date_and_time.replace("\n", ""))
                            console.log("date is", date)
                            const options = { month: "short", day: "numeric", year: "numeric" }
                            let final_date = date.toLocaleDateString('en-GB', options),
                                image = single.field_image, path = single.field_path_alias
                            return <div className="single-card">
                                <Link to={path}><img className="image w-100 mb-3" src={image ? BASEURL + image : "/assets/noimage.png"} /></Link>
                                <p className="orange-text pt-1 mb-2 text-sm-semibold">{final_date}</p>
                                <Link to={path}><div className="d-flex justify-content-between">
                                    <h5 className="primary-text title px-0 mb-2 mt-0">{single.title}</h5>
                                    <Link to={path}><img className="open-arrow" src="/assets/top-right-arrow.png" /></Link>
                                </div></Link>
                                <p className="gray-text text-md-regular lineclamp-two mb-4">{single.field_short_description}</p>
                            </div>

                        }) : this.state.tab === "upcoming" ? this.state.up_events?.map((single, ind) => {
                            let date = new Date(HtmlParser(single.field_event_date_and_time.replace("\n", "")))
                            const options = { month: "short", day: "numeric", year: "numeric" }
                            let final_date = date.toLocaleDateString('en-GB', options),
                                image = single.field_image, path = single.field_path_alias
                            return <div className="single-card">
                                <Link to={path}><img className="image w-100 mb-3" src={image ? BASEURL + image : "/assets/noimage.png"} /></Link>
                                <p className="orange-text pt-1 mb-2 text-sm-semibold">{final_date}</p>
                                <Link to={path}><div className="d-flex justify-content-between">
                                    <h5 className="primary-text title px-0 mb-2 mt-0">{single.title}</h5>
                                    <Link to={path}><img className="open-arrow" src="/assets/top-right-arrow.png" /></Link>
                                </div></Link>
                                <p className="gray-text text-md-regular lineclamp-two mb-4">{single.field_short_description ? HtmlParser(single.field_short_description) : ""}</p>
                            </div>

                        }) : this.state.events?.map((single, ind) => {
                            let date = new Date(single.attributes.field_event_date_and_t)
                            const options = { month: "short", day: "numeric", year: "numeric" }
                            let final_date = date.toLocaleDateString('en-GB', options),
                                image = single.relationships.field_image.data ? this.state.included.find(x => x.id === single.relationships.field_image.data.id).attributes.uri.url : "",
                                path = single.attributes.field_path_alias
                            return <div className="single-card">
                                <Link to={path}><img className="image w-100 mb-3" src={image ? BASEURL + image : "/assets/noimage.png"} /></Link>
                                <p className="orange-text pt-1 mb-2 text-sm-semibold">{final_date}</p>
                                <Link to={path}><div className="d-flex justify-content-between">
                                    <h5 className="primary-text title px-0 mb-2 mt-0">{single.attributes.title}</h5>
                                    <Link to={path}><img className="open-arrow" src="/assets/top-right-arrow.png" /></Link>
                                </div></Link>
                                <p className="gray-text text-md-regular lineclamp-two mb-4">{single.attributes.field_short_description ? HtmlParser(single.attributes.field_short_description.value) : ""}</p>
                            </div>

                        })}
                        {this.state.tab === "past" ? this.state.past_events ? this.state.past_events.length === 0 ? <div className="no-data">No Past Events found</div> : "" : <Loader type="carousel" /> : ""}
                        {this.state.tab === "upcoming" ? this.state.up_events ? this.state.up_events.length === 0 ? <div className="no-data">No Upcoming Events found</div> : "" : <Loader type="carousel" /> : ""}
                        {this.state.tab === "" ? this.state.events ? this.state.events.length === 0 ? <div className="no-data">No Events found</div> : "" : <Loader type="carousel" /> : ""}
                    </div>
                    {(this.state.tab === "past" && this.state.pastcount > 1) || (this.state.tab === "upcoming" && this.state.upcount > 1) || (this.state.tab === "" && this.state.counta > 1) ? <><div className="pagination-1 d-sm-block d-none">
                        <ReactPaginate
                            nextLabel={<span className="d-flex align-items-center"><span className="mr-2">Next</span> <img src="/assets/paginate-arrow.png" className='page-icon right-icon' /></span>}
                            // onPageChange={this.handlePageClick}
                            onClick={(e) => this.handlePageClick(e)}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            pageCount={this.state.tab == "past" ? this.state.pastcount : this.state.tab == "upcoming" ? this.state.upcount : this.state.counta}
                            previousLabel={<span className="d-flex align-items-center"><img src="/assets/paginate-arrow.png" className='page-icon left-icon' /><span className="ml-2">Previous</span></span>}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item arrow previous-arrow"
                            previousLinkClassName="page-link arrow"
                            nextClassName="page-item arrow next-arrow"
                            nextLinkClassName="page-link arrow"
                            breakLabel="..."
                            breakClassName="dots"
                            breakLinkClassName="page-link"
                            containerClassName="pagination justify-content-between"
                            activeClassName="active"
                            activeLinkClassName="activePage"
                            renderOnZeroPageCount={null}
                            forcePage={this.state.page}
                        />
                    </div>
                        <div className="mobile-pagination d-flex d-sm-none w-100 justify-content-between">
                            <img src="/assets/paginate-arrow.png" className='page-icon left-icon' />
                            Page {this.state.page} of {this.state.tab == "past" ? this.state.pastcount : this.state.tab == "upcoming" ? this.state.upcount : this.state.counta}
                            <img src="/assets/paginate-arrow.png" className='page-icon right-icon r-180deg' />
                        </div></> : ""}
                </div>
                <Footer />
            </div>
        )
    }
}
export default Events;