import React, { Component } from "react";

class Alert extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: null,
        }
    }
    render() {
        return (
            <div style={{ display: this.props.open ? '' : 'none', backgroundColor: this.props.success ? '#28a745' : '', position: 'fixed', top: 0, width: '100%' }} className='formvalidation'>
                {this.props.message}
            </div>
        )
    }
}
export default Alert;