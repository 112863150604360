import React, { Component } from "react";
import "./interviews.css"
import Header from "../header/header";
import Footer from "../footer/footer";
import cachios from "cachios";
import ReactPaginate from "react-paginate"
import { BASEURL } from "../api/api";
import HtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import Loader from "../styles/loader"

class Interviews extends Component {
    constructor() {
        super()
        this.state = {
            interviews: null,
            page: 0,
            count: 0,
            limit: 6
        }
        this.handlePageClick = this.handlePageClick.bind((this))
    }
    componentDidMount() {
        this.getInterviews(0)
        cachios.get(`${BASEURL}/jsonapi/block_content/title_description?filter[field_title.value]=Interviews`)
            .then(resp => {
                this.setState({
                    banner: resp.data.data[0]
                })
            })
            .catch(err => {
                console.error("errro on interview list page", err)
            })
    }
    getInterviews(page) {
        let offset = page * this.state.limit
        window.scrollTo(0, 0)
        cachios.get(`${BASEURL}/jsonapi/node/interview?include=field_image&sort=-created&page[offset]=${offset}&page[limit]=${this.state.limit}`)
            .then(resp => {
                this.setState({
                    interviews: resp.data.data,
                    interviewIncluded: resp.data.included,
                    count: Math.ceil(resp.data.meta?.count / this.state.limit)
                })
            })
            .catch(err => {
                console.error("errro on interview list page", err)
            })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            this.setState({
                interviews: null
            }, () => {
                this.getInterviews(this.state.page)
            })
        }
    }
    handlePageClick(e) {
        console.log("clicked", e)
        this.setState({
            page: e.nextSelectedPage
        })
    }
    render() {
        return (
            <div id="interviews-page">
                <Header />
                <div className="interviews-page new-container" >
                    <div className="header-text-banner mx-auto">
                        {this.state.banner ? <>
                            <p className="sub-head orange-text text-center">{this.state.banner.attributes.field_title?.value}</p>
                            <h2 className="heading primary-text text-center">{this.state.banner.attributes.info}</h2>
                            <p className="support-text teritiary-text text-center">{this.state.banner.attributes.field_description.length ? HtmlParser(this.state.banner.attributes.field_description[0].value) : ""}</p>
                        </> : <Loader type="content" />}
                    </div>
                    <h4 className="display-xs-semibold primary-text mt-5 mb-4">All interviews</h4>
                    <div className="interview-list carousel-one d-flex flex-wrap">
                        {this.state.interviews ? this.state.interviews?.map((single, ind) => {
                            let image = single.relationships.field_image.data ? this.state.interviewIncluded?.find(x => x.id === single.relationships.field_image.data.id)?.attributes.uri.url : "",
                                path = single.attributes.field_path_alias
                            return <div className="card-one">
                                <Link to={path}><img className="main-image w-100" src={image ? BASEURL + image : "/assets/noimage.png"} /></Link>
                                <p className="text-sm-semibold orange-text mb-2">{single.attributes.field_interviewer_name?.value}</p>
                                <Link to={path}> <div className="link-text mb-2">
                                    <h4 className="display-xs-semibold primary-text">{single.attributes.title}</h4>
                                    <Link to={path}><img width="10px" height="10px" src="/assets/top-right-arrow.png" /></Link>
                                </div></Link>
                                <p className="text-md-regular lineclamp-two teritiary-text mb-0">{single.attributes.field_short_description ? HtmlParser(single.attributes.field_short_description.value) : ""}</p>
                            </div>
                        }) : <Loader type="carousel" />}
                        {this.state.interviews?.length == 0 ? <div className="no-data">Interviews not found</div> : ""}
                    </div>
                    {this.state.count > 1 ? <><div className="pagination-1 d-sm-block d-none">
                        <ReactPaginate
                            nextLabel={<span className="d-flex align-items-center"><span className="mr-2">Next</span> <img src="/assets/paginate-arrow.png" className='page-icon right-icon' /></span>}
                            // onPageChange={this.handlePageClick}
                            onClick={this.handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            pageCount={this.state.count}
                            previousLabel={<span className="d-flex align-items-center"><img src="/assets/paginate-arrow.png" className='page-icon left-icon' /><span className="ml-2">Previous</span></span>}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="arrow previous-arrow"
                            previousLinkClassName="page-link arrow"
                            nextClassName="arrow next-arrow"
                            nextLinkClassName="page-link arrow"
                            breakLabel="..."
                            breakClassName="dots"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            activeLinkClassName="activePage"
                            renderOnZeroPageCount={null}
                            forcePage={this.state.page}
                        />
                    </div>
                        <div className="mobile-pagination d-flex d-sm-none">
                            <img src="/assets/paginate-arrow.png" role={this.state.page == 0 ? "" : "button"} onClick={() => this.state.page !== 0 && this.setState({ page: this.state.page - 1 })} className='page-icon left-icon' />
                            Page {this.state.page + 1} of {this.state.count}
                            <img src="/assets/paginate-arrow.png" role={this.state.page == this.state.count - 1 ? "" : "button"} onClick={() => this.state.page !== this.state.count - 1 && this.setState({ page: this.state.page + 1 })} className='page-icon right-icon r-180deg' />
                        </div>
                    </> : ""}
                </div>
                <Footer />
            </div>
        )
    }
}
export default Interviews;