import React, { Component } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import "./investment.css"
import cachios from "cachios";
import Loader from "../styles/loader"
import { BASEURL } from "../api/api";
import HtmlParser from "react-html-parser";
import ReactPaginate from "react-paginate"


class InvestmentNews extends Component {
    constructor() {
        super()
        this.state = {
            banner: null,
            investments: null,
            page: 0,
            count: 0,
            limit: 6
        }
    }
    componentDidMount() {
        cachios.get(`${BASEURL}/jsonapi/block_content/title_description?filter[field_title.value]=Investment News`)
            .then(resp => {
                this.setState({
                    banner: resp.data.data.length ? resp.data.data[0] : null
                })
            })
            .catch(err => {
                console.error("error on banner investment news", err)
            })
        this.getInvestment()
    }
    getInvestment(page) {
        let offset = page == 0 ? 0 : page * this.state.limit - 1
        cachios.get(`${BASEURL}/jsonapi/node/blog?filter[field_blog_type]=Investment News&page[offset]=${offset}&page[limit]=${this.state.limit}`)
            .then(resp => {
                this.setState({
                    investments: resp.data.data,
                    count: Math.ceil(resp.data.meta?.count / this.state.limit)
                })
            })
            .catch(err => {
                console.error("error on banner investment news", err)
            })
    }

    handlePageClick(e) {
        console.log("clicked", e)
        this.setState({
            page: e.nextSelectedPage
        }, () => {
            this.getInvestment(this.state.page)
        })
    }
    render() {
        return (
            <div id="investment_news">
                <Header />
                <div className="investment_news newContainer">
                    <div className="header-text-banner mx-auto my-sm-5 my-3">
                        {this.state.banner ? <>
                            <p className="orange-text sub-head text-center mb-2 pb-1">{this.state.banner.attributes.field_title?.value}</p>
                            <h2 className="heading black-text text-center mb-4">{this.state.banner.attributes.info}</h2>
                            <p className="support-text teritiary-text text-center mb-0">{this.state.banner.attributes.field_description.length ? HtmlParser(this.state.banner.attributes.field_description[0].value) : ""}</p>
                        </> : <Loader type="content" />}
                    </div>
                    <div className="investment-content mx-auto">
                        {this.state.investments?.map((single, ind) => {
                            let date = new Date(single.attributes.field_date)
                            let preffered_date = date.toLocaleDateString("en-US", { day: "numeric", month: "long", year: "numeric" })
                            return (
                                <div className="single-investment">
                                    {single.attributes.field_date ? <p className="text-md-medium text-uppercase">{preffered_date}</p> : ""}
                                    <h4 className="display-xs-semibold primary-text mb-3">{single.attributes.title}</h4>
                                    <p className="teritiary-text text-md-regular mb-0">{single.attributes.field_short_description ? HtmlParser(single.attributes.field_short_description.value) : ""}</p>
                                    {single.attributes.field_link ? <a className="orange-text mt-3 openinner" href={single.attributes.field_link.uri} target="_blank"><span className="orange-text">{single.attributes.field_link.title ? single.attributes.field_link.title : "Explore"}</span> <img width="10px" height="10px" src="/assets/orange-top-right-arrow.png" /></a> : ""}
                                </div>
                            )
                        })}
                        {this.state.investments ? this.state.investments.length === 0 ? <div className="nodata">No Investment News found</div> : "" :
                            <div>
                                <div className="mb-3"><Loader type="content" /></div>
                                <Loader type="content" />
                            </div>}
                    </div>
                    {this.state.count > 1 ? <><div className="pagination-1 d-sm-block d-none">
                        <ReactPaginate
                            nextLabel={<span className="d-flex align-items-center"><span className="mr-2">Next</span> <img src="/assets/paginate-arrow.png" className='page-icon right-icon' /></span>}
                            // onPageChange={this.handlePageClick}
                            onClick={this.handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            pageCount={this.state.count}
                            previousLabel={<span className="d-flex align-items-center"><img src="/assets/paginate-arrow.png" className='page-icon left-icon' /><span className="ml-2">Previous</span></span>}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="arrow previous-arrow"
                            previousLinkClassName="page-link arrow"
                            nextClassName="arrow next-arrow"
                            nextLinkClassName="page-link arrow"
                            breakLabel="..."
                            breakClassName="dots"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            activeLinkClassName="activePage"
                            renderOnZeroPageCount={null}
                            forcePage={this.state.page}
                        />
                    </div>
                        <div className="mobile-pagination d-flex d-sm-none">
                            <img src="/assets/paginate-arrow.png" role={this.state.page == 0 ? "" : "button"} onClick={() => this.state.page !== 0 && this.setState({ page: this.state.page - 1 })} className='page-icon left-icon' />
                            Page {this.state.page + 1} of {this.state.count}
                            <img src="/assets/paginate-arrow.png" role={this.state.page == this.state.count - 1 ? "" : "button"} onClick={() => this.state.page !== this.state.count - 1 && this.setState({ page: this.state.page + 1 })} className='page-icon right-icon r-180deg' />
                        </div>
                    </> : <div className="mt-5" />}
                </div>
                <Footer />
            </div >
        )
    }
} export default InvestmentNews;