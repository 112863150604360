import React, { Component } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import cachios from "cachios";
import { BASEURL } from "../api/api";
import Loader from "../styles/loader";
import HtmlParser from "react-html-parser";
import OtherDetails from "../styles/other-details";
import { Link } from "react-router-dom";


class Episode extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: this.props.match.params.title.replace(/-/g, " ").split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
            ep_no: this.props.match.params.no,
            details: null
        }
        this.updateData = this.updateData.bind(this)
    }
    componentDidMount() {
        console.log("details on episode", this.state.title, ":", this.state.ep_no)
        cachios.get(`${BASEURL}/jsonapi/node/podcast?filter[field_path_alias]=${window.location.pathname}`)
            .then(resp => {
                if (resp.data.data.length) {
                    const regex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/)|youtu\.be\/)[\w-]{11}(\?[\w-=&]*)?$/
                    let data = resp.data.data[0]
                    let link = data.attributes.field_embed_player
                    console.log('match', link, "te", regex.test(data.attributes.field_embed_player))
                    this.setState({
                        details: resp.data.data[0],
                        type: regex.test(link) ? "youtube" : ""
                    })
                }
            })
            .catch(err => {
                console.error("error podcast inner", err)
            })
    }
    updateData() {

    }
    render() {
        return (
            <div id="episode-page" className="episode-page">
                <Header />
                {this.state.details ? <>
                    <div className="pink-bg newContainer">
                        {this.state.details ? <div className="breadcrumbs d-flex">
                            <p className="teritiary-text text-sm-regular mb-0">Blog</p>
                            <img src="/assets/bread-right.png" />
                            <p className="teritiary-text text-sm-regular mb-0"><Link className="teritiary-text" to="/podcast">Podcast</Link></p>
                            <img src="/assets/bread-right.png" />
                            <p className="text-sm-regular mb-0 orange-text">{this.state.details.attributes.title}</p>
                        </div> : ""}
                        <div className="orange-back" />
                        <div className="head-banner-container mx-auto mb-5">
                            <div className="episode-box text-sm-medium orange-text"><span className="episode tes">Episode</span><span className="number">{this.state.details.attributes.field_episode}</span></div>
                            <h2 className="heading text-center primary-text mb-4">{this.state.details.attributes.title}</h2>
                            <p className="text-xl-regular text-center teritiary-text">{this.state.details.attributes.field_short_descriptions ? HtmlParser(this.state.details.attributes.field_short_descriptions.value) : ""}</p>
                        </div>
                        <div className={`iframe-container ${this.state.type}`}><iframe className="iframe" src={this.state.details.attributes.field_embed_player}></iframe></div>
                    </div>
                    <div className={`newContainer main-description-container ${this.state.type}`}>
                        <div className="main-description mx-auto">
                            {this.state.details.attributes.body ? HtmlParser(this.state.details.attributes.body.value.replace(/src="\/sites/g, `src="${BASEURL}/sites`)) : ""}
                            <OtherDetails updateData={this.updateData()} />
                        </div>
                    </div>
                </> : <div className="newContainer"> <Loader type="content" /></div>}

                <Footer />
            </div>
        )
    }
} export default Episode;