import React, { Component } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import "./podcast.css"
import cachios from "cachios";
import { BASEURL } from "../api/api";
import Loader from "../styles/loader"
import HtmlParser from "react-html-parser";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate"


class Podcast extends Component {
    constructor() {
        super()
        this.state = {
            banner: null,
            podcast: null,
            podcastIncluded: [],
            count: 0,
            page: 0,
            limit: 6
        }
    }
    componentDidMount() {
        cachios.get(`${BASEURL}/jsonapi/block_content/title_description?filter[field_title.value]=Podcast`)
            .then(resp => {
                this.setState({
                    banner: resp.data.data.length ? resp.data.data[0] : null
                })
            })
            .catch(err => {
                console.error("error", err)
            })
        this.getPodcast(0)
    }
    getPodcast(page) {
        let offset = page * this.state.limit
        cachios.get(`${BASEURL}/jsonapi/node/podcast?include=field_thumbnail&page[offset]=${offset}&page[limit]=${this.state.limit}`)
            .then(resp => {
                this.setState({
                    podcast: resp.data.data,
                    podcastIncluded: resp.data.included,
                    count: Math.ceil(resp.data.meta?.count / this.state.limit)
                })
            })
            .catch(err => {
                console.error("error", err)
            })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            this.getPodcast(this.state.page)
        }
    }
    handlePageClick(e) {
        console.log("clicked", e)
        this.setState({
            page: e.nextSelectedPage
        })
    }
    render() {
        return (
            <div id="podcast-page">
                <Header />
                <div className="podcast-page newContainer">
                    {this.state.banner ? <div className="header-text-banner mx-auto my-5">
                        <p className="orange-text sub-head text-center">{this.state.banner.attributes.field_title?.value}</p>
                        <h2 className="heading black-text text-center">{this.state.banner.attributes.info}</h2>
                        <p className="support-text teritiary-text text-center">{this.state.banner.attributes.field_description.length ? HtmlParser(this.state.banner.attributes.field_description[0].value) : ""}</p>
                    </div> : <Loader type="content" />}
                    <div>
                        <h4 className="sub-heading black-text mb-4 pb-2">Latest Podcast</h4>
                        <div className="carousel-one">
                            {this.state.podcast?.map((single, ind) => {
                                let image = single.relationships.field_thumbnail.data ? this.state.podcastIncluded?.find(x => x.id === single.relationships.field_thumbnail.data.id)?.attributes.uri.url : ""
                                    , path = single.attributes.field_path_alias
                                return (<div className="card-one">
                                    <Link to={path}>
                                        <div className="position-relative">
                                            <img className={`caorusel-img mb-3 w-100 ${image ?? "border"}`} src={image ? BASEURL + image : "/assets/noimage.png"} />
                                            <p className="ep-no white-text">EP.{single.attributes.field_episode}</p>
                                        </div>
                                    </Link>
                                    <div className="episode-details text-md-regular text-uppercase"><span>{single.attributes.field_author_name?.value}</span> <div className="grey-dot" /><span>{single.attributes.field_topic}</span></div>
                                    <Link to={path}><h4 className="display-xs-semibold primary-text mb-2">{single.attributes.title}</h4></Link>
                                    <p className="text-md-regular lineclamp-two primary-text mb-4">{single.attributes.field_short_description ? HtmlParser(single.attributes.field_short_description.value) : ""}</p>
                                    <div className="play-minutes">
                                        <Link to={path}><div className="text-md-regular orange-text  play"><img width={"8px"} height={"9px"} src="/assets/orange-play.png" />Play this episode</div></Link>
                                        <p className="minutes text-md-regular mb-0">{single.attributes.field_duration ? `${single.attributes.field_duration} min` : ""}</p>
                                    </div>
                                </div>)
                            })}
                        </div>
                        {this.state.podcast ? this.state.podcast.length === 0 ? <div className="no-content"></div> : "" : <Loader type="carousel" />}
                    </div>
                    {this.state.count > 1 ? <><div className="pagination-1 d-sm-block d-none">
                        <ReactPaginate
                            nextLabel={<span className="d-flex align-items-center"><span className="mr-2">Next</span> <img src="/assets/paginate-arrow.png" className='page-icon right-icon' /></span>}
                            // onPageChange={this.handlePageClick}
                            onClick={this.handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            pageCount={this.state.count}
                            previousLabel={<span className="d-flex align-items-center"><img src="/assets/paginate-arrow.png" className='page-icon left-icon' /><span className="ml-2">Previous</span></span>}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="arrow previous-arrow"
                            previousLinkClassName="page-link arrow"
                            nextClassName="arrow next-arrow"
                            nextLinkClassName="page-link arrow"
                            breakLabel="..."
                            breakClassName="dots"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            activeLinkClassName="activePage"
                            renderOnZeroPageCount={null}
                            forcePage={this.state.page}
                        />
                    </div>
                        <div className="mobile-pagination d-flex d-sm-none">
                            <img src="/assets/paginate-arrow.png" role={this.state.page == 0 ? "" : "button"} onClick={() => this.state.page !== 0 && this.setState({ page: this.state.page - 1 })} className='page-icon left-icon' />
                            Page {this.state.page + 1} of {this.state.count}
                            <img src="/assets/paginate-arrow.png" role={this.state.page == this.state.count - 1 ? "" : "button"} onClick={() => this.state.page !== this.state.count - 1 && this.setState({ page: this.state.page + 1 })} className='page-icon right-icon r-180deg' />
                        </div></> : ""}
                </div>
                <Footer />
            </div>
        )
    }
}
export default Podcast;
