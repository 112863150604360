import React, { Component } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import "./testimonials.css"
import { testimonials } from "./testimonial_data";
import { BASEURL } from "../api/api";
import cachios from "cachios"
import ReactHtmlParser from "react-html-parser"
import Loader from "../styles/loader";
import HtmlParser from "react-html-parser";


class Testimonials extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: null,
            testimonials: null,
            testIncluded: []
        }
    }
    setOpen(i) {
        console.log("firing", i)
        this.setState({
            open: i
        })
    }
    componentDidMount() {
        cachios.get(`${BASEURL}/jsonapi/block_content/title_description?filter[field_title.value]=Testimonials`)
            .then(resp => {
                let data = resp.data.data.length ? resp.data.data[0] : null
                this.setState({
                    banner: data,

                })
            })
            .catch(err => {
                console.error(err, "error banner testimonials")
            })
        cachios.get(`${BASEURL}/jsonapi/node/testimonial?include=field_dp,field_company_logo`)
            .then(resp => {
                let data = resp.data.data
                this.setState({
                    testimonials: data,
                    testIncluded: resp.data.included
                })
            })
            .catch(err => {
                console.error(err, "error on testimonials")
            })
    }
    render() {
        return (
            <div>
                <Header />
                <div className="testimonial-container">
                    {this.state.banner ? <div className="header-text-banner mx-auto my-5">
                        <p className="orange-text sub-head text-center">{this.state.banner?.attributes.field_title?.value}</p>
                        <h4 className="heading black-text text-center">{this.state.banner.attributes.info}</h4>
                        <p className="support-text teritiary-text text-center mb-0">{this.state.banner.attributes.field_description.length ? HtmlParser(this.state.banner.attributes.field_description[0].value) : ""}</p>
                    </div> : <div className="my-4"> <Loader type="content" /></div>}
                    <div className="testimonial-carousel">
                        {this.state.testimonials?.map((single, ind) => {
                            let dp = single.relationships.field_dp.data ? this.state.testIncluded?.find(x => x.id === single.relationships.field_dp.data.id)?.attributes.uri.url : "",
                                logo = single.relationships.field_company_logo.data ? this.state.testIncluded?.find(x => x.id === single.relationships.field_company_logo.data.id)?.attributes.uri.url : ""
                            return (
                                <div className={`testimonial-details pt-4 d-sm-flex d-block align-items-center ${ind % 2 === 0 ? "flex-sm-row-reverse" : ""}`}>
                                    <div className="img-details position-relative mb-4 mb-sm-0">
                                        <img className="main-img w-100" src={dp ? BASEURL + dp : "/assets/no-user.jpg"} />
                                        <div className="detail-box">
                                            <h6 className="display-xs-semibold white-text name">{single.attributes.title}</h6>
                                        </div>
                                        <div className="gradient-box" />
                                    </div>
                                    <div className="other-details">
                                        <p className="text-xl-regular primary-text quotes mb-2">{single.attributes.field_description ? ReactHtmlParser(single.attributes.field_description.value) : ""}</p>
                                        {logo ? <img className="light-img mb-sm-4 mb-2" src={BASEURL + logo} /> : ""}
                                        <p className="name display-xs-semibold primary-text mb-1">{single.attributes.title}</p>
                                        <p className="text-md-regular black-text">
                                            {single.attributes.field_designation.value}
                                        </p>
                                    </div>
                                </div>
                            )
                        })}
                        {this.state.testimonials ? this.state.testimonials.length === 0 ? <div className="no-data">No Testimonials found</div> : "" : <div className="my-4"> <Loader type="content" /></div>}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Testimonials;
