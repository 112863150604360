import React, { Component } from "react"
import Header from "../header/header"
import Footer from "../footer/footer"
import "./assets.css"
import cachios from "cachios"
import { BASEURL } from "../api/api"
import htmlParser from "react-html-parser"
import Loader from "../styles/loader"

class Assets extends Component {
    constructor() {
        super()
        this.state = {
            assets: null,
            details: null
        }
    }
    componentDidMount() {
        cachios.get(`${BASEURL}/jsonapi/node/assets`)
            .then(resp => {
                let data = resp.data.data
                this.setState({
                    assets: data
                })

            })
            .catch(err => {
                console.log(err, "error in assets page")
            })
        cachios.get(`${BASEURL}/jsonapi/block_content/title_description?filter[drupal_internal__id]=34`)
            .then(resp => {
                let data = resp.data.data.length ? resp.data.data[0] : null
                this.setState({
                    details: data?.attributes
                })

            })
            .catch(err => {
                console.log(err, "error in assets page")
            })
    }
    render() {
        return (
            <div id="assets_page">
                <Header />
                <div className="assets_page">
                    <div className="header-text-banner my-sm-5 my-3  mx-auto d-flex flex-column align-items-center">
                        {this.state.details ? <>
                            <p className="sub-head orange-text text-center mb-2 pb-1">{this.state.details?.field_title?.value}</p>
                            <h2 className="heading black-text text-center mb-sm-4 mb-3">{this.state.details?.info}</h2>
                            <p className="support-text text-center teritiary-text">{this.state.details?.field_description.length ? htmlParser(this.state.details?.field_description[0].value) : ""} </p>
                        </> : <Loader type="content" />}
                    </div>
                    <div className="guidelines d-flex flex-wrap">
                        {this.state.assets?.map((single, ind) => {
                            return <div className="single-guide col-sm-6 col-12">
                                <h4 className="text-lg-semibold primary-text mb-2">{single.attributes.title}</h4>
                                <p className="text-md-regular teritiary-text mb-sm-4 mb-3">{single.attributes.body ? htmlParser(single.attributes.body.value) : ""}</p>
                                {single.attributes.field_download ?
                                    <a className="link orange-text text-md-semibold" href={single.attributes.field_download?.uri} target="_blank">{single.attributes.field_download.title?single.attributes.field_download.title:"Download"}<img className="link-arrow" src="/assets/orange-top_right-arrow.png" />
                                    </a>
                                    : ""}
                            </div>
                        })}
                        {this.state.assets ? this.state.assets.length === 0 ? <div className="no-data">No Assets found</div> : "" : <div className="row w-100">{Array(4).fill(0).map(x => { return <div className="col-sm-6 col-12 mb-3"><Loader type="content" /></div> })}</div>}
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}
export default Assets;