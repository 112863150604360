import React, { Component } from "react";
import "./press_release.css"
import Header from "../header/header";
import Footer from "../footer/footer";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import releases from "./press_data";
import cachios from 'cachios'
import { BASE } from "../api/api";
import HtmlParser from "react-html-parser";
import Loader from "../styles/loader";


class PressRelease extends Component {
    constructor(props) {
        super(props);
        this.handlePageClick = this.handlePageClick.bind(this);
        this.state = {
            counta: 0,
            releases: null,
            releaseIncluded: [],
            page: 0,
            limit: 9
        }
    }
    handlePageClick(e) {
        console.log("clicked", e)
        this.setState({
            page: e.nextSelectedPage
        })
    }
    componentDidMount() {
        this.getPressRelease(0, this.state.limit)
        cachios.get(BASE + '/jsonapi/block_content/title_description?filter[field_title.value]=Press', {
            ttl: 300 /* seconds */,
        })
            .then((data) => {
                // field_title:
                this.setState({
                    details: data.data.data[0].attributes
                })
            })
            .catch((err) => {
                console.log('not found', err)
            })
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.page !== this.state.page) {
            let offset = this.state.page === 0 ? 0 : this.state.page * 9 - 1
            console.log("new offset", offset)
            this.getPressRelease(offset, this.state.limit)
        }
    }
    getPressRelease(offset, limit) {
        cachios.get(BASE + `/jsonapi/node/press_release?include=field_image,field_author_image&sort=-created&page[offset]=${offset}&page[limit]=${limit}`, {
            ttl: 300 /* seconds */,
        })
            .then(resp => {
                console.log("press releases", resp.data.data)
                this.setState({
                    releases: resp.data.data,
                    releaseIncluded: resp.data.included,
                    counta: Math.ceil(resp.data.meta.count / 9)
                })
            })
            .catch(err => {

            })
    }
    render() {

        return (
            <div id="press_releases">
                <Header />
                <div className="press_releases press_releases-container">
                    {this.state.details ? <div className="page-head-container">
                        <h5 className="sub-head orange-text mb-2">{this.state.details.field_title.value}</h5>
                        <h2 className="heading black-text pt-1 mb-sm-4 mb-3">{this.state.details.info}</h2>
                        <p className="support-text teritiary-text">{this.state.details.field_description.length ? HtmlParser(this.state.details.field_description[0].value) : ""}</p>
                    </div> : ""}
                    <div className="carousel d-flex flex-wrap">
                        {this.state.releases ? this.state.releases.filter((x, i) => i == 0).map((single, ind) => {
                            let image = single.relationships.field_image.data ? this.state.releaseIncluded.find(x => x.id === single.relationships.field_image.data.id) : ""
                            let avatar = single.relationships.field_author_image.data ? this.state.releaseIncluded.find(x => x.id === single.relationships.field_author_image.data.id) : ""
                            const date = new Date(single.attributes.field_date);
                            const options = { day: 'numeric', month: 'short', year: 'numeric' };
                            let formattedDate = date.toLocaleDateString('en-GB', options),
                                path = single.attributes.field_path_alias
                            return <div className="card-1">
                                <Link to={path}><img className="w-100 card-img mb-3" src={image ? BASE + image.attributes.uri.url : "/assets/noimage.png"} /></Link>
                                <p className="newspaper orange-text mb-2">{single.attributes.field_source?.value}</p>
                                <Link to={path}><div className="d-flex justify-content-between">
                                    <h4 className="title mb-2 mt-0 px-0 black-text">{single.attributes.title}</h4>
                                    <Link to={path}><img className="upright-arrow" src="/assets/arrow-up-right.png" /></Link>
                                </div></Link>
                                <p className="desc lineclamp-two teritiary-text">{single.attributes.field_short_description ? HtmlParser(single.attributes.field_short_description.value) : ""}</p>
                                <p className="date teritiary-text">{`${single.attributes.field_date ? formattedDate : ""}`}</p>
                            </div>
                        }) : ""}
                        <div className="d-sm-none d-block w-100">
                            <h4 className="main-sub-head mb-2 pb-1">All</h4>
                            <select className="filter-select-box">
                                <option defaultChecked>Most Recent</option>
                            </select>
                        </div>
                        {this.state.releases ? this.state.releases.filter((x, i) => i !== 0).map((single, ind) => {
                            let image = single.relationships.field_image.data ? this.state.releaseIncluded.find(x => x.id === single.relationships.field_image.data.id) : ""
                            let avatar = single.relationships.field_author_image.data ? this.state.releaseIncluded.find(x => x.id === single.relationships.field_author_image.data.id) : ""
                            const date = new Date(single.attributes.field_date);
                            const options = { day: 'numeric', month: 'short', year: 'numeric' };
                            let formattedDate = date.toLocaleDateString('en-GB', options),
                                path = single.attributes.field_path_alias
                            return <div className="card-1">
                                <Link to={path}>
                                    <img className="w-100 card-img mb-3" src={image ? BASE + image.attributes.uri.url : "/assets/noimage.png"} />
                                </Link>
                                <p className="newspaper orange-text mb-2">{single.attributes.field_source?.value}</p>
                                <Link to={path}><div className="d-flex justify-content-between">
                                    <h4 className="title mb-2 mt-0 px-0 black-text">{single.attributes.title}</h4>
                                    <Link to={path}><img className="upright-arrow" src="/assets/arrow-up-right.png" /></Link>
                                </div></Link>
                                <p className="desc lineclamp-two teritiary-text">{single.attributes.field_short_description ? HtmlParser(single.attributes.field_short_description.value) : ""}</p>
                                <div className="d-flex d-sm-none author-details">
                                    <img src={avatar ? BASE + avatar.attributes.uri.url : "/assets/avatar.png"} />
                                    <div className="author-date">
                                        <p className="primary-text author mb-0">{single.attributes.field_author_name ? single.attributes.field_author_name.value : ""}</p>
                                        <p className="date mb-0 teritiary-text">{single.attributes.field_date ? formattedDate : ""}</p>
                                    </div>
                                </div>
                                <p className="date d-none d-sm-block teritiary-text">{single.attributes.field_date ? formattedDate : ""}</p>
                            </div>
                        }) : ""}
                        {this.state.releases ? this.state.releases.length === 0 ? <div>No Press Release found</div> : "" : <Loader type="carousel" scale={{ width: "100%", height: "40px" }} />}
                    </div>
                    {this.state.counta > 1 ? <><div className="pagination-1 d-sm-block d-none">
                        <ReactPaginate
                            nextLabel={<span className="d-flex align-items-center"><span className="mr-2">Next</span> <img src="/assets/paginate-arrow.png" className='page-icon right-icon' /></span>}
                            // onPageChange={this.handlePageClick}
                            onClick={this.handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={2}
                            pageCount={this.state.counta}
                            previousLabel={<span className="d-flex align-items-center"><img src="/assets/paginate-arrow.png" className='page-icon left-icon' /><span className="ml-2">Previous</span></span>}
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="arrow previous-arrow"
                            previousLinkClassName="page-link arrow"
                            nextClassName="arrow next-arrow"
                            nextLinkClassName="page-link arrow"
                            breakLabel="..."
                            breakClassName="dots"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            activeLinkClassName="activePage"
                            renderOnZeroPageCount={null}
                            forcePage={this.state.page}
                        />
                    </div>
                        <div className="mobile-pagination d-flex d-sm-none">
                            <img src="/assets/paginate-arrow.png" role={this.state.page == 0 ? "" : "button"} onClick={() => this.state.page !== 0 && this.setState({ page: this.state.page - 1 })} className='page-icon left-icon' />
                            Page {this.state.page + 1} of {this.state.counta}
                            <img src="/assets/paginate-arrow.png" role={this.state.page == this.state.counta - 1 ? "" : "button"} onClick={() => this.state.page !== this.state.counta - 1 && this.setState({ page: this.state.page + 1 })} className='page-icon right-icon r-180deg' />
                        </div>
                    </> : ""}

                </div>
                <Footer />
            </div>
        )

    }
}
export default PressRelease;