import React, { Component } from "react";
import Header from "../header/header";
import Footer from "../footer/footer";
import cachios from "cachios";
import { BASEURL } from "../api/api";
import HtmlParser from "react-html-parser";
import OtherDetails from "../styles/other-details";
import Alert from "../styles/alert";
import Loader from "../styles/loader";
import { Link } from "react-router-dom";


class SingleInterview extends Component {
    constructor() {
        super()
        this.state = {
            data: null
        }
        this.updateData = this.updateData.bind(this)
    }
    componentDidMount() {
        let title = this.props.match.params.title.replace(/-/g, " ")
        cachios.get(`${BASEURL}/jsonapi/node/interview?include=field_image&filter[field_path_alias]=${window.location.pathname}`)
            .then(resp => {
                if (resp.data.data.length) {
                    let data = resp.data.data[0],
                        included = resp.data.included
                    this.setState({
                        data: data,
                        image: data.relationships.field_image.data ? included?.find(x => x.id === data.relationships.field_image.data.id)?.attributes.uri.url : ""
                    })

                }
            })
            .catch(err => {
                console.error("error on single intervew", err)
            })
    }
    updateData() {
        console.log("updating data")
        this.setState({
            alert: true,
            alerttxt: "Copied to clipboard"
        }, () => {
            setTimeout(
                function () {
                    this.setState({ alert: false, alerttxt: "" });
                }
                    .bind(this),
                3000
            );
        })
    };
    render() {
        return (
            <div id="single-interview">
                <Alert message={this.state.alerttxt} success={true} open={this.state.alert} />
                <Header />
                {this.state.data ? <div className="single-interview-page pt-0 new-container">
                    {this.state.data ? <div className="breadcrumbs d-flex">
                        <p className="teritiary-text text-sm-regular mb-0">Press</p>
                        <img src="/assets/bread-right.png" />
                        <p className="teritiary-text text-sm-regular mb-0"><Link className="teritiary-text" to="/interviews">Interviews</Link></p>
                        <img src="/assets/bread-right.png" />
                        <p className="text-sm-regular mb-0 orange-text">{this.state.data.attributes.title}</p>
                    </div> : ""}
                    <div className="header-text-banner align-items-start">
                        <h2 className="display-lg-semibold  primary-text mb-3">{this.state.data?.attributes.title}</h2>
                        <p className="text-xl-regular teritiary-text">{this.state.data?.attributes.field_short_description ? HtmlParser(this.state.data.attributes.field_short_description.value) : ""}</p>
                    </div>
                    {this.state.image ? < img className="main-image w-100" src={BASEURL + this.state.image} /> : ""}
                    <div className="interviewer-share d-sm-flex d-block justify-content-between flex-wrap mt-4 pt-2">
                        <div>
                            {this.state.data.attributes.field_interviewer_name ? <>
                                <p className="text-sm-semibold orange-text">Interviewed by</p>
                                <p className="text-lg-Medium primary-text">{this.state.data.attributes.field_interviewer_name?.value}</p>
                            </> : ""}
                        </div>
                        <OtherDetails title={this.state.data.attributes.title} updateData={this.updateData} />
                    </div>
                    <div className="main-description mt-sm-5 mt-3 pt-sm-5 pt-3 mx-auto">
                        <div className="mb-4">
                            {this.state.data.attributes.body ? HtmlParser(this.state.data.attributes.body.value.replace(/src="\/sites\/default/g, `src="${BASEURL}/sites/default`)) : ""}
                        </div>
                        <OtherDetails title={this.state.data.attributes.title} updateData={this.updateData} />
                    </div>

                </div> : <Loader type="content" />}
                <Footer />
            </div>
        )
    }
}
export default SingleInterview;