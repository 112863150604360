
//local
// export const BASEURL='http://139.59.20.32/rajih/rehbar_new/web/';
// export const BASE='http://139.59.20.32/';

//live
// export const BASEURL='https://api.rehbar.co.in';
// export const BASE='https://api.rehbar.co.in';
// export const FURL='https://rehbar.co.in/';

//dev
export const BASEURL='https://rehbar-dr.hzdev.tridz.in';
export const BASE='https://rehbar-dr.hzdev.tridz.in';
export const FURL='https://rehbar.hzdev.tridz.in/';

//devel
// export const BASEURL='http://devel.tridz.com/rehbar8/web';
// export const BASE='http://devel.tridz.com/rehbar8/web';
// export const FURL='http://devel.tridz.com/rehbar8/web/';