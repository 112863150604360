import React, { Component } from 'react';
import Axios from 'axios';
import Recaptcha from 'react-recaptcha'
import { BASEURL } from "../api/api";
import   './mailchimp.css'

class Mail extends Component {
    constructor(props) {
        super(props);
        this.callback2=this.callback2.bind(this)
        this.loaded=this.loaded.bind(this)
        this.state = {
            email:'',
            phone:'',
            invest:props.invest?true:false
        }
    }

    validateEmail(email) {
        //eslint-disable-next-line
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

        if (reg.test(email) === false) {

            return false;
        }

        return true;

    }

    onSubmit = (e) => {
        // e.preventDefault();
        this.setState({ formsubmit: true });
        if (this.state.email) {
            if (this.validateEmail(this.state.email)) {
                if (this.state.pophum2) {
                    this.setState({
                    btntext2: 'Sending...'
                })

                const formbody =
                {
                    "contact_form": [{ "target_id": "single_enquiry_mail_submiting_fo" }],
                    "message": [{ "value": "Message field not available" }],
                    "field_email": [{ "value": this.state.email }],
                    "subject": [{ "value": "Enquiry Mail" }]

                }
                Axios(BASEURL + '/entity/contact_message?_format=json', {
                    // Axios('http://devel.tridz.com/rehbar8/web/contact_message?_format=json', {
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: formbody
                })
                    .then((res) => {
                        this.setState({
                            email: '',
                            formvalid: true,
                            formsuccess: true,
                            formvalidtext: 'Thank you!Your Submission has been recieved',
                            formsubmit: false,
                            form2: true,
                            btntext2: 'Send',
                            pophum2:false
                        })
                        setTimeout(
                            function () {
                                this.setState({ formvalid: false, form2: false });
                            }
                                .bind(this),
                            3000
                        );
                    })
                    .catch((err) => {
                        console.log(err, 'error')
                        this.setState({
                            formvalid: true,
                            btntext2: 'Send'

                        })

                    })
                }
                else {
                    this.setState({
                        formvalid: true,
                        form2: true,
                        formsuccess: false,
                        formvalidtext: 'Please verify you are a human'
    
                    })
                    setTimeout(
                        function () {
                            this.setState({ formvalid: false, form2: false });
                        }
                            .bind(this),
                        3000
                    );
                }
                
            }
            else {
                this.setState({
                    formvalid: true,
                    form2: true,
                    formsuccess: false,
                    formvalidtext: 'Please enter a valid email address'

                })
                setTimeout(
                    function () {
                        this.setState({ formvalid: false, form2: false });
                    }
                        .bind(this),
                    3000
                );
            }
        }
        else {
            this.setState({
                formvalid: true,
                form2: true,
                formsuccess: false,
                formvalidtext: 'Please enter an email address'

            })
            setTimeout(
                function () {
                    this.setState({ formvalid: false, form2: false });
                }
                    .bind(this),
                3000
            );
        }

    }

    investfn(e) {
        if (this.state.invest) {
            if (this.state.email) {
                if (this.validateEmail(this.state.email)) {
                    if (this.state.pophum2) {
                        this.onSubmit(e)

                    }
                    else{
                        e.preventDefault();
                        this.setState({
                            formvalid: true,
                            form2: true,
                            formsuccess: false,
                            formvalidtext: 'Please verify you are a human'
        
                        })
                        setTimeout(
                            function () {
                                this.setState({ formvalid: false, form2: false });
                            }
                                .bind(this),
                            3000
                        );
                    }
                }
                else {
                    e.preventDefault();
                    this.setState({
                        formvalid: true,
                        form2: true,
                        formsuccess: false,
                        formvalidtext: 'Please enter a valid email address'
    
                    })
                    setTimeout(
                        function () {
                            this.setState({ formvalid: false, form2: false });
                        }
                            .bind(this),
                        3000
                    );
                }
            }
            else {
                e.preventDefault();
                this.setState({
                    formvalid: true,
                    form2: true,
                    formsuccess: false,
                    formvalidtext: 'Please enter an email address'
    
                })
                setTimeout(
                    function () {
                        this.setState({ formvalid: false, form2: false });
                    }
                        .bind(this),
                    3000
                );
            }
        }

    }

    clearform(){
        setTimeout(
            function () {
                this.setState({
                    email:'',
                    phone:''
                })
            }
                .bind(this),
            3000
        );
        
    }
    callback2(response){
        if(response){
            console.log('user verified')

            this.setState({
                pophum2:true
            })
        }
    }

    loaded(){
        console.log('loaded')
    }
    
    change(e){
        this.setState({
            email:e.target.value
        })
    }
    changenum(e){
        this.setState({
            phone:e.target.value
        })
    }
render() {
    return (
        <div className=''>
            {/* <link href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css" rel="stylesheet" type="text/css"/> */}
            <div style={{ display: this.state.formvalid && this.state.form2 ? '' : 'none', backgroundColor: this.state.formsuccess ? '#28a745' : '' }} className='formvalidation'>
                    {this.state.formvalidtext}
            </div>
            <div id="mc_embed_signup">
                <form action="https://rehbar.us9.list-manage.com/subscribe/post?u=9dc1343d0af6884635113513e&amp;id=e6fadf326d" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" novalidate>
                    <div className="mailchimpnew" id="mc_embed_signup_scroll">
                        
                        <div className="mc-field-group">
                            <div className="card-title">{this.state.invest?'Join our growing investor community': 'Stay Updated'}</div>
                            <p className="card-text">{this.state.invest?'Contact our investor relations team today': 'We will keep you in the loop with ecosystem trends and insights'}</p>
                            <input placeholder="Your email id" onChange={(e)=>this.change(e)} type="email" value={this.state.email} name="EMAIL" className="required email" id="mce-EMAIL"/>
                        </div>
                        {!this.state.invest?<div className="mc-field-group size1of2">
                            <input placeholder="Phone" onChange={(e)=>this.changenum(e)} value={this.state.phone} type="number" name="PHONE" className="" id="mce-PHONE"/>
                        </div>:''}
                        <div id="mce-responses" className="clear">
                            <div className="response" id="mce-error-response" style={{display:"none"}}></div>
                            <div className="response" id="mce-success-response" style={{display:"none"}}></div>
                        </div>   
                        <div aria-hidden="true"><input  style={{display:"none"}} type="text" name="b_9dc1343d0af6884635113513e_e6fadf326d" tabindex="-1" value=""/></div>
                        <div className="captcha">
                                {!this.state.popup && this.state.email &&this.state.invest?
                                    <Recaptcha
                                        sitekey="6LfjijoUAAAAAEnqWSRTc9YnoXZC4ZAocDpkznfw"
                                        render="explicit"
                                        onloadCallback={this.loaded}
                                        verifyCallback={this.callback2}
                                    /> : ''}
                            </div>
                        <div className="clear">
                            <input onClick={(e)=>this.investfn(e)} type="submit" value={this.state.invest?"Send":"Sign Up for our Newsletters" }name="subscribe" id="mc-embedded-subscribe" className="button"/></div>
                    </div>
                </form>
            </div>
        </div>      
        
            )
}   
}

export default Mail;
